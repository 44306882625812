import 'antd/dist/reset.css'
import { useEffect, useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import axios from 'axios'

import { getCurrentUser, getFirebase } from 'utils/firebase'
import '../styles/tailwind.css'

const firebase = getFirebase()

const API_URL = 'https://logs.simplesat.io/api'

export default function MyApp({ Component, pageProps }) {
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState(getCurrentUser())
  const router = useRouter()

  useEffect(() => {
    if (router.pathname) {
      setCurrentUser(getCurrentUser())
    }
  }, [router.pathname])

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      const isLoggedIn = Boolean(user)
      if (isLoggedIn) {
        user.getIdToken().then((userToken) => {
          axios
            .post(`${API_URL}/authenticate/`, { jwt: userToken })
            .then(() => {
              setCurrentUser(user)
              setLoading(false)
            })
            .catch(() => {
              alert('Please use simplesat.io email to sign in.')
              return firebase
                .auth()
                .signOut()
                .finally(() => {
                  setCurrentUser(null)
                  setLoading(false)
                })
            })
        })
      } else {
        setCurrentUser(null)
        setLoading(false)
      }
    })

    return unsubscribe
  }, [])

  if (loading) {
    return <Loader />
  }

  const isNotLogInPage = !router.pathname.match(/\/login\/?/)
  if (!currentUser && isNotLogInPage) {
    router.push('/login/')
  }

  return <Component {...pageProps} />
}

function Loader() {
  return (
    <>
      <Head>
        <link
          rel="stylesheet"
          href="https://pagecdn.io/lib/font-awesome/5.10.0-11/css/all.min.css"
          integrity="sha256-p9TTWD+813MlLaxMXMbTA7wN/ArzGyW/L7c5+KkjOkM="
          crossOrigin="anonymous"
        />
      </Head>
      <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0 top-1/2">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
    </>
  )
}
