import firebase from 'firebase/app'
import 'firebase/auth'

export function getFirebase() {
  _initializeFirebase()

  return firebase
}

function _initializeFirebase() {
  if (!firebase.apps.length) {
    const firebaseConfig = {
      apiKey: 'AIzaSyDCSJWTQiT7tsaNndm_DdoKfwfcPFMdbS8',
      authDomain: 'simple-logs.firebaseapp.com',
      projectId: 'simple-logs',
      storageBucket: 'simple-logs.appspot.com',
      messagingSenderId: '1034409937053',
      appId: '1:1034409937053:web:51e992e8c812fc5d5fac74',
    }
    firebase.initializeApp(firebaseConfig)
  } else {
    firebase.app() // if already initialized, use that one
  }
}

export function getCurrentUser() {
  return getFirebase().auth().currentUser
}
